const componentToggle = () => {
	// missing forEach on NodeList for IE11
	if (window.NodeList && !NodeList.prototype.forEach) {
		NodeList.prototype.forEach = Array.prototype.forEach;
	}

	function findAncestor(el, sel) {
		while ((el = el.parentElement) && !(el.matches || el.matchesSelector).call(el, sel));
		return el;
	}

	const toggles = document.querySelectorAll(".toggle");

	toggles.forEach(function (toggle) {
		const items = toggle.querySelectorAll(".toggle__item");
		const questions = toggle.querySelectorAll(".toggle__button");

		questions.forEach(function (question) {
			question.addEventListener("click", function () {
				const thisItem = findAncestor(this, ".toggle__item");

                // if(!thisItem.classList.contains('toggle__item--show')) {
                //     setTimeout(() => {
                //         const thisItemPosition = thisItem.getBoundingClientRect().top;
                //         const offsetPosition = thisItemPosition + window.pageYOffset;
    
                //         window.scrollTo({
                //             top: offsetPosition - 10,
                //             behavior: "smooth",
                //         });
                //     }, 200);
                // }

				items.forEach(function (item) {
					if (thisItem == item) {
						thisItem.classList.toggle("toggle__item--show");
						return;
					}

					item.classList.remove("toggle__item--show");
				});
			});
		});
	});
};

export default componentToggle;
