import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

const componentParallax = () => {
    const componentParallax = document.querySelectorAll("[data-animation='parallax']");

    if (componentParallax) {
        componentParallax.forEach((componentParallaxItem) => {
            const componentParallaxBottom = componentParallaxItem.querySelectorAll("[data-parallax-block='bottom']");
            const componentParallaxMid = componentParallaxItem.querySelectorAll("[data-parallax-block='mid']");

            const tl = gsap.timeline({
                scrollTrigger: {
                    trigger: componentParallaxItem,
                    start: "top bottom",
                    end: "bottom top",
                    scrub: 2
                }
            });

            gsap.utils.toArray(componentParallaxBottom).forEach(layer => {
                tl.to(layer, { yPercent: -200, ease: "none" }, 0)
            });

            gsap.utils.toArray(componentParallaxMid).forEach(layer => {
                tl.to(layer, { yPercent: -50, ease: "none" }, 0)
            });

            window.addEventListener('resize', () => {
                tl.scrollTrigger.refresh();
            });

            // gsap.to(componentParallaxEl, {
            //     y: "-150%",
            //     ease: "none",
            //     scrollTrigger: {
            //         trigger: componentParallaxItem,
            //         start: "top bottom",
            //         end: "bottom top",
            //         scrub: 0.5
            //     }
            // });


            // const componentParallaxAni = gsap.timeline({
            //     defaults: {
            //         ease: "none",
            //         // clearProps: "all",
            //     },
            // });

            // componentParallaxEl.forEach(componentParallaxElItem => {
            //     componentParallaxAni.to(componentParallaxElItem, { y: "200px" });
            // });

            // ScrollTrigger.create({
            //     ease: "none",
            //     animation: componentParallaxAni,
            //     trigger: componentParallaxEl,
            //     start: "top top",
            //     end: "bottom top",
            //     scrub: true,
            //     markers: true
            // });
        });
    }
};

export default componentParallax;
