import Swiper, { Autoplay, Pagination, Navigation } from "swiper";

const componentSlider = () => {

    const swiperGallery = new Swiper(".swiper-gallery", {
        modules: [Autoplay, Pagination, Navigation],
        spaceBetween: 30,
        autoHeight: true,
        loop: true,
        allowTouchMove: true,
     
        pagination: {
           el: ".swiper-gallery__pagination",
           clickable: true,
        },
        navigation: {
           nextEl: '.swiper-gallery__next',
           prevEl: '.swiper-gallery__prev',
        },
     })
};

export default componentSlider;
