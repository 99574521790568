import gsap from "gsap";

const componentScrollToTop = () => {
	let scrollTopBtn = document.getElementById("scrollTopBtn");

	// show the button when user scrolls down 20px
	window.addEventListener('scroll', () => {
		if (document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
			scrollTopBtn.classList.add('show');
		} else {
			scrollTopBtn.classList.remove('show');
		}
	});

	// scroll to top function
	scrollTopBtn.addEventListener("click", function () {
		// window.scrollTo({top: 0, behavior: 'smooth'})
		gsap.to(window, {duration: .5, scrollTo: 0});
	})
};

export default componentScrollToTop;
