import gsap from "gsap";

const componentNav = () => {

    const navToggle = document.querySelector(".nav-toggle");

    if (navToggle) {

        const navMenu = document.querySelector(".nav-menu");
        const navOverlay = document.querySelector(".nav-overlay");
        const navLinks = navMenu.querySelectorAll("nav ul li");
        const navHrefs = navMenu.querySelectorAll(".nav-menu-links");
        const body = document.body;

        const tlMenu = gsap.timeline({
            paused: true,
            reversed: true,

            defaults: {
                ease: "power3.out",
            },
        });

        gsap.set(navLinks, { opacity: "0" });

        tlMenu
            .to(navMenu, { visibility: 'visible' }, 0)
            .to(navOverlay, { scale: 150, duration: 1 }, 0)
            .to(
                navLinks,
                {
                    opacity: 1,
                    left: 0,
                    stagger: {
                        each: 0.1,
                    },
                },
                ">-.75"
            );

        navToggle.addEventListener('click', () => {
            if (navToggle.classList.contains('nav-toggle--active')) {
                closeNavigation();
            } else {
                openNavigation();
            }
        });

        function openNavigation() {
            body.classList.add('overflow');
            navToggle.classList.add('nav-toggle--active');
            navToggle.setAttribute('aria-expanded', 'true');
            tlMenu.play();
        }

        function closeNavigation() {
            body.classList.remove('overflow');
            navToggle.classList.remove('nav-toggle--active');
            navToggle.setAttribute('aria-expanded', 'false');
            tlMenu.reverse(.5);
        }

        navHrefs.forEach(function (navHref) {
            navHref.addEventListener('click', function (e) {
                e.stopImmediatePropagation();

                location.hash = this.firstElementChild.getAttribute("href");
                closeNavigation()
            });
        })

    }
};

export default componentNav;
