import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";
import { componentAccordion, componentAnimation, componentNav, componentParallax, componentSlider, componentScrollToTop, componentToggle } from "./components";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

componentAnimation();
componentAccordion();
componentParallax();
componentSlider();
componentScrollToTop();
componentToggle();
componentNav();


// Mobile Height Hack
const mobileHeight = () => {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}

mobileHeight();

window.addEventListener('resize', () => {
	mobileHeight();
});


// E-Mail Encrypter
function UnCryptMailto(s) {
	var n = 0;
	var r = "";
	for (var i = 0; i < s.length; i++) {
		n = s.charCodeAt(i);
		if (n >= 8364) {
			n = 128;
		}
		r += String.fromCharCode(n - 1);
	}
	return r;
}

window.linkTo_UnCryptMailto = (s) => {
	location.href = UnCryptMailto(s);
}